import {destroyTokens, getToken, saveTokens} from "./storage";
import axios from "axios";

export const domain = process.env.DOMAIN || "aws-cloud-audit.com";
// export const domain = process.env.REACT_APP_DOMAIN;
// export const baseUrl = process.env.REACT_APP_BASE_URL || `https://${domain}`;
export const baseUrlMain = `https://main.${domain}`;
export const baseUrlUser = `https://user.${domain}`;
export const baseUrlSupport = `https://support.${domain}`;
export const baseUrlDashboard = `https://dashboard.${domain}`;
export const baseUrlAccount = `https://account.${domain}`;
export const baseUrlCompany = `https://company.${domain}`;
export const baseUrlBilling = `https://billing.${domain}`;
export const baseUrlAnalyzer = `https://hla.${domain}`;

export const baseUrlAi = `https://ai.${domain}`;
export const authUrl = `https://auth.${domain}`;

export const config = {
    baseURL: baseUrlMain,
    headers: {
        "Content-Type": "application/json",
    },
};

export const axiosInstance = axios.create(config);

let _retry = false;

axiosInstance.interceptors.response.use(
    (res) => {
        document.body.classList.add("loading-indicator");
        return res;
    },
    async (error) => {
        const config = error.config;

        // if (config?.url?.includes("/auth") && error?.response) {
        //     if (error.response.status === 401 && config.url === "/auth/refresh") {
        //         destroyTokens();
        //     }
        console.log("1111111111 error.response.status", error);
        if (error.response.status === 401 && !_retry) {
            _retry = true;
            try {
                console.log(_retry, "retry");
            } catch (_error) {
                return Promise.reject(_error);
            }
            // }
        }
        document.body.classList.remove("loading-indicator");
        return Promise.reject(error);
    },
);

axiosInstance.interceptors.request.use(
    config => {
        if (!getToken("access_token") && !config.url.includes("auth")) {
            window.location.href = "/";
        }
        config.headers.Authorization = "OAuth " + getToken("access_token") || "";
        config.headers.IdToken = getToken("id_token") || "";
        document.body.classList.add("loading-indicator");
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);
