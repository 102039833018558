import {configureStore} from "@reduxjs/toolkit";
import headerSlice from "../features/common/headerSlice";
import modalSlice from "../features/common/modalSlice";
import leadsSlice from "../features/leads/leadSlice";
import {rightDrawerSlice} from "../features/common/rightDrawerSlice";
import leftSideMenuSlice from "../features/common/leftSideMenu";
import usersSlice from "../features/users/usersSlice";
import authSlice from "../features/user/api/authSlice";
import userInfoSlice from "../features/common/userInfoSlice";
import groupsSlice from "../features/groups/api/groupsSlice";
import accountsSlice from "../features/accounts/api/accountsSlice";
// import aiSlice from "../features/accounts/aiSlice";
import wellArchitectedSlice from "../features/wellArchitected/wellArchitectedSlice";
import dashboardSlice from "../features/dashboard/dashboardSlice";
import billingSlice from "../features/billing/billingSlice";
import aiSlice from "../features/chat/api/aiSlice";
import analizeSlice from "../features/trafficAnalyzer/trafficAnalyzerSlice";

const combinedReducer = {
    header: headerSlice,
    rightDrawer: rightDrawerSlice,
    modal: modalSlice,
    lead: leadsSlice,
    leftSideMenu: leftSideMenuSlice,
    // user: userSlice,
    users: usersSlice,
    auth: authSlice,
    userInfo: userInfoSlice,
    groups: groupsSlice,
    // mainLogo: logoSlice,
    accounts: accountsSlice,
    wellArchitected: wellArchitectedSlice,
    billing: billingSlice,
    dashboard: dashboardSlice,
    ai: aiSlice,
    analize: analizeSlice

};

export default configureStore({
    reducer: combinedReducer,
});
