import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {axiosInstance, baseUrlAnalyzer} from "../../config/api";
import {toast} from "react-toastify";

export const fetchAnalyzerData = createAsyncThunk(
    "analyzer/fetchData",
    async ({tenant = "", account = ""}, {rejectWithValue}) => {
        try {
            const response = await axiosInstance.post(baseUrlAnalyzer + "/api/get", {
                tenant,
                account,
            });
            return response.data; 
        } catch (error) {
            toast.error(error?.response?.data?.message || "An error occurred");
            return rejectWithValue(error?.response?.data);
        }
    }
);

const analizeSlice = createSlice({
    name: "analize",
    initialState: {
        isLoading: false,
        tenants: [],
        accounts: [],
        photoLink: "",
        selectedTenant: "",
        selectedAccount: "",
        error: null,
    },
    reducers: {
        setSelectedTenant(state, action) {
            state.selectedTenant = action.payload;
        },
        setSelectedAccount(state, action) {
            state.selectedAccount = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchAnalyzerData.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(fetchAnalyzerData.fulfilled, (state, action) => {
                state.isLoading = false;
                const data = action.payload;

                if (data?.groups) {
                    state.tenants = data.groups;
                }
                if (data?.accounts) {
                    state.accounts = data.accounts;
                }
                if (data?.message) {
                    state.photoLink = data.message;
                }
            })
            .addCase(fetchAnalyzerData.rejected, (state, action) => {
                state.isLoading = false;
                state.error = action.payload?.message || "Fetch failed";
            });
    },
});

export const {
    setSelectedTenant,
    setSelectedAccount,
} = analizeSlice.actions;

export default analizeSlice.reducer;
